// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// react-router
// import { Link } from 'react-router-dom'
import "../../src/index.css";

// semantic-ui
import { Header, Segment, Container, Image, Grid } from 'semantic-ui-react'

export const HomePage = ()=>{
  const redirect = ()=> {
    window.location.href=process.env.NODE_ENV === "production"
    ? "https://www.generali.com.my/partnership"
    : "https://www.generali.com.my/partnership-uat";
  }

  redirect()
}
 
export default HomePage
